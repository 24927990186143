<template>
    <div>
        <div class="block experience">
            
        </div>
    </div>
</template>

<script>
export default {
    name: 'Job Experience',

}
</script>

<style scoped>

</style>