<template>
    <div class="main">

    </div>
</template>

<script>
export default {
    name
}
</script>

<style scoped>
.main{
   background-color: #45A29F;
}
</style>